const initialState = {
  mic: false,
  ifMicAllow: false,
  isVirtualKeyboard: false,
};

export default function MicReducer(state = initialState, action) {
  switch (action.type) {
    case "ACTION_MIC_DENIED":
      return {
        mic: action.mic,
        ifMicAllow: action.ifMicAllow,
      };
    case "ACTION_MIC_ALLOW":
      return {
        ifMicAllow: action.ifMicAllow,
      };
    case "ACTION_KEYBOARD_SHOW":
      return {
        isVirtualKeyboard: action.isActiveKeyboard,
      };
    case "ACTION_KEYBOARD_HIDE":
      return {
        isVirtualKeyboard: action.isActiveKeyboard,
      };

    default:
      return state;
  }
}
