const initialState = {
  saved: [],
};

export default function SavedReducer(state = initialState, action) {
  switch (action.type) {
    case "ACTION_SET_SAVED":
      return {
        saved: action.saved,
      };
    default:
      return state;
  }
}
